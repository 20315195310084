<template>
  <div>

    <!-- 新增组织按钮 -->
    <el-button type="" @click="createOrganization" class="create-organization"
               :class="{ 'custom-reset-button': isTopLevelSelected, 'custom-button': !isTopLevelSelected }"
               :disabled="!canAddOrganization">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp;&nbsp; {{ $t('userManage.addOrganization') }}
    </el-button>

    <el-dialog v-model="showDialog"
               width="500" @close="handleDialogClose"
               style="text-align: left;"
    >
      <template #header>
        {{ $t('userManage.addOrganization') }}
      </template>

      <hr class="top-separator"/>

      <el-form :model="addOrganizationForm" :rules="addOrganizationFormRules" ref="addOrganizationForm"
               label-width="100px"
               label-position="top">
        <el-form-item prop="organizationName">
          <label> {{ $t('userManage.superiorOrganization') }}</label>
          <el-tree-select
              v-model="addOrganizationForm.organizationName"
              :data="organizationOptions"
              :props="{ label: 'label', children: 'children'}"
              check-strictly
              :placeholder="$t('userManage.selectParentOrganization')"
              :render-after-expand="false"
              clearable filterable
              style="width: 100%;"
          />
        </el-form-item>

        <el-form-item :label="$t('userManage.organizationName')" prop="organizationNameText">
          <el-input v-model="addOrganizationForm.organizationNameText"
                    :placeholder="$t('userManage.enterOrganizationName')"
                    clearable></el-input>
        </el-form-item>

        <el-form-item :label="$t('userManage.organizationNumber')" prop="organizationNumber">
          <el-input v-model="addOrganizationForm.organizationNumber"
                    :placeholder="$t('userManage.enterOrganizationNumber')"
                    clearable></el-input>
        </el-form-item>

        <el-form-item :label="$t('userManage.note')" prop="remark">
          <el-input
              type="textarea"
              v-model="addOrganizationForm.remark"
              :placeholder="$t('userManage.enterNote')"
              maxlength="100"
              show-word-limit
              clearable>
          </el-input>
        </el-form-item>

      </el-form>

      <hr class="dialog-separator"/>

      <div class="dialog-footer">
        <el-button @click="cancelAddOrganization" class="custom-reset-button"> {{ $t('userManage.cancel') }}</el-button>
        <el-button class="button-background-color" @click="confirmAddOrganization" :loading="isButtonLoading">{{
            $t('userManage.confirm')
          }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 名称搜索框 -->
    <el-input v-model="searchName" :placeholder="$t('userManage.enterName')" class="search-name" clearable>
      <template #suffix>
        <el-icon class="el-input__icon"
                 @click="clearSearch" style="color: black;cursor: default;">
          <search/>
        </el-icon>
      </template>
    </el-input>


    <!-- 树形控件展示 -->
    <el-tree :data="organizationData" :props="treeProps" v-model="selectedOrganization" accordion
             :filter-node-method="filterNode" :default-expanded-keys="defaultExpandedKeys" @node-click="handleNodeClick"
             :default-expand-all="true"
             node-key="key"
             :expand-on-click-node=false
             @node-collapse="handleCollapse"
             class="tree-organization" v-loading="loading" element-loading-text="Loading...">
      <template v-slot="{ data }">
        <div class="node-content"
             :class="{ 'node-selected': selectedNodeKey === data.key }"
             @mouseover="handleMouseOver(data)" @mouseout="handleMouseOut(data)">
          <div class="label-wrapper">
            <span class="node-label">{{ data.label }}</span>
          </div>
          <el-icon class="delete-icon" @click="handleDeleteNode(data.key)"
                   v-show="data.key !== '1' && data.showDeleteIcon">
            <Delete/>
          </el-icon>
        </div>
      </template>

    </el-tree>


  </div>

  <DeleteDialog
      :visible="deleteDialogVisible"
      :message="$t('userManage.confirmDeleteOrganization')"
      @confirm="confirmDelete"
      @cancel="cancelDeleteNode"
      @update:visible="deleteDialogVisible = $event"
  ></DeleteDialog>

</template>

<script>
import {Delete, Plus, Search} from "@element-plus/icons-vue";
import {
  addOrganizations,
  deleteOrganizations,
  getOneAndTwoLevelOrganizations,
  getOrganizationsData,
  listToTree
} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";
import DeleteDialog from "@/components/DeleteDialog.vue";

export default {
  components: {DeleteDialog, Delete, Search, Plus},
  emits: ['organizationSelected'],
  mixins: [dialogMixin],
  data() {
    return {
      searchName: "",
      selectedOrganization: [],
      defaultExpandedKeys: [1, 2], // 包含所有节点的 key 值
      label: '',
      isTopLevelSelected: false,//新增组织按钮是否可以点击，false是非顶级节点，不可点击
      selectedNodeKey: null, // 存储当前选中节点的 key
      showDialog: false, // 控制弹框是否显示
      addOrganizationForm: {
        organizationName: 1,
        organizationNameText: '',
        organizationNumber: '',
        remark: '',
      },
      organizationData: [],
      organizationOptions: [],

      addOrganizationFormRules: {
        organizationNameText: [
          {required: true, message: this.$t('userManage.enterOrganizationName'), trigger: 'blur'},
        ],
        organizationNumber: [
          {required: true, message: this.$t('userManage.enterOrganizationNumber'), trigger: 'blur'}
        ],
      },
      organizationOptionsLoaded: false, // 标志位，确保接口只调用一次
      loading: false, // 添加加载状态
      deleteDialogVisible: false,
      nodeIdToDelete: null,
    };
  },
  computed: {
    treeProps() {
      return {
        children: 'children',
        label: 'label'
      };
    },
    canAddOrganization() {
      // 如果组织列表为空，或者选中顶级组织，可以新增
      return (
          this.organizationData.length === 0 || this.selectedNodeKey === "1"
      );
    },
  },
  mounted() {
    this.fetchOrganizationData();
  },
  watch: {
    searchName(newVal) {
      if (newVal) {
        this.filterTreeData();
      } else {
        // 如果搜索关键字被清空，重新加载原始数据
        this.fetchOrganizationData();
      }
    }
  },
  methods: {
    handleDialogClose() {
      this.resetForm();
    },
    handleMouseOver(node) {
      if (node.key !== 1) {
        node.showDeleteIcon = true; // 直接赋值
      }
    },
    handleMouseOut(node) {
      if (node.key !== 1) {
        node.showDeleteIcon = false; // 直接赋值
      }
    },
    async createOrganization() {
      if (this.canAddOrganization) {
        if (!this.organizationOptionsLoaded) {
          await this.fetchOrganizationOptions();
          this.organizationOptionsLoaded = true;
        }
        this.showDialog = true; // 显示弹框
      }
    },
    filterNode(value, data) {
      return data.label.toLowerCase().includes(value.toLowerCase());
    },
    //新增组织下的请输入名称文本框，点击右端的搜索按钮的方法
    clearSearch() {

    },
    handleNodeClick(node) {
      this.selectedNodeKey = node.key; // 设置当前选中节点的 key
      this.isTopLevelSelected = node.key === '1';//1为顶级节点的key
      this.$emit('organizationSelected', node); // 触发事件，将选中的组织传递给父组件
    },

    handleCollapse(data, node) {
      // 如果点击的是顶级节点，则阻止默认的收缩行为
      if (node.level === 1) {
        data.expanded = true;
      }
    },

    async handleDeleteNode(nodeId) {
      this.deleteDialogVisible = true;
      this.nodeIdToDelete = nodeId;
    },
    async confirmDelete() {
      try {
        await deleteOrganizations(this.nodeIdToDelete);
        // 数据删除成功后的逻辑，例如重新获取组织列表或从当前列表中移除已删除的节点
        await this.fetchOrganizationData(); // 假设这个方法重新加载组织数据
      } catch (error) {
        console.error(this.$t('userManage.deleteErrorMessage'), error);
      } finally {
        this.deleteDialogVisible = false;
        this.nodeIdToDelete = null;
      }
    },
    cancelDeleteNode() {
      this.deleteDialogVisible = false;
      this.nodeIdToDelete = null;
    },

    cancelAddOrganization() {
      this.showDialog = false;
      this.resetForm();
    },

    async confirmAddOrganization() {
      await this.handleButtonAction(async () => {
        // 首先验证表单数据是否有效
        const valid = await this.$refs.addOrganizationForm.validate();
        if (valid) {
            const formData = {
              pid: this.addOrganizationForm.organizationName,
              name: this.addOrganizationForm.organizationNameText,
              code: this.addOrganizationForm.organizationNumber,
              remark: this.addOrganizationForm.remark,
              level: this.addOrganizationForm.organizationName === 1 ? 2 : (this.addOrganizationForm.organizationName === 0 ? 1 : 0), // 当组织列表为空时，设置level为1
            };
            // 表单数据有效，调用后端接口
            const res = await addOrganizations(formData);

            if (res.code === -1) {
              this.$message.error(res.message);
            } else {
              // 数据提交成功后的操作，例如关闭弹窗，清空表单，刷新列表等
              this.showDialog = false;
              this.resetForm();
              await this.fetchOrganizationData();
            }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      // 重置表单逻辑
      this.$refs.addOrganizationForm.resetFields();
    },
    async fetchOrganizationData() {
      if (this.searchName) return; // 如果当前有搜索关键字，不重新加载数据
      this.loading = true; // 开始加载数据
      try {
        const response = await getOrganizationsData();
        this.organizationData = this.buildTreeStructure(response.data);
      } catch (error) {
        console.error(this.$t('ProductManage.ConfigManage.fetchOrganizationFailed'), error);
      } finally {
        this.loading = false; // 请求完成后设置loading为false
      }
    },
    buildTreeStructure(data) {
      let map = {}, roots = [];
      // 首先初始化每个节点，建立映射表
      data.forEach(item => {
        map[item.id] = {...item, children: []};
      });
      // 根据pid构建树形结构
      data.forEach(item => {
        let parent = map[item.pid];
        if (parent) {
          // 如果找到父节点，将当前节点添加到父节点的children数组
          parent.children.push(map[item.id]);
        } else {
          // 如果没有找到父节点，说明是根节点
          roots.push(map[item.id]);
        }
      });
      // 将树形结构转换为需要的格式
      return roots.map(root => this.processNode(root));
    },

    processNode(node) {
      // 转换每个节点到期望的格式，这里可以根据需要添加或修改属性
      return {
        key: node.id.toString(), // 确保key是字符串或数字
        label: node.name,
        showDeleteIcon: false,
        children: node.children.map(child => this.processNode(child))
      };
    },
    // 添加一个方法来过滤 treeData
    filterTreeData() {
      const filter = (data, keyword) => data.filter(item => {
        // 检查当前节点的名称是否包含关键字
        if (item.label.toLowerCase().includes(keyword.toLowerCase())) {
          return true;
        }
        // 如果有子节点，递归检查子节点
        if (item.children) {
          item.children = filter(item.children, keyword);
          return item.children.length;
        }
        return false;
      });

      // 使用定义的 filter 函数过滤 treeData
      this.organizationData = filter(this.organizationData, this.searchName);
    },

    async fetchOrganizationOptions() {
      try {
        // 调用后端接口获取组织数据
        let searchCondition = {};

        searchCondition["level-in"] = [1, 2];
        const response = await getOneAndTwoLevelOrganizations(searchCondition);
        const treeData = listToTree(response.data);
        this.organizationOptions = this.transformOrganizationsData(treeData);
      } catch (error) {
        console.error('Error fetching organization options:', error);
      }
    },
    transformOrganizationsData(organizations) {
      return (organizations || []).map(org => ({
        value: org.id,
        label: org.name,
        children: this.transformOrganizationsData(org.children),
      }));
    }

  },
};
</script>

<style scoped>

.node-selected {
  background-color: rgb(238, 250, 235); /* 自定义选中节点的背景色 */
  color: rgb(78, 152, 78);
}

/* 为不同层级设置不同的label-wrapper宽度 */
:deep( .el-tree-node--level-1 > .el-tree-node__content .label-wrapper ) {
  width: 100%;
}

:deep( .el-tree-node--level-2 > .el-tree-node__content .label-wrapper) {
  width: 90%;
}

:deep(.el-tree-node--level-3 > .el-tree-node__content .label-wrapper) {
  width: 80%;
}

:deep(.tree-organization .el-tree-node .node-content) {
  display: flex;
  justify-content: space-between; /* 使标签和图标分布在两端 */
  align-items: center; /* 垂直居中对齐 */
  width: 100%; /* 使容器占满整个宽度 */
}

/* 设置.label-wrapper的背景色 */
:deep(.label-wrapper) {
  padding: 5px; /* 根据需要调整 */
  display: flex;
  align-items: center;
}

:deep( .tree-organization .el-tree-node .delete-icon) {
  margin-left: auto; /* 将图标推到最右边 */
  cursor: pointer;
}

.custom-button {
  background-color: rgb(245, 245, 245); /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217); /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184); /* 设置边框颜色为红色，如果需要 */
}

.custom-button:hover {
  background-color: rgb(245, 245, 245); /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217); /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184); /* 鼠标悬停时保持相同的文字颜色 */
}

.create-organization {
  width: 300px;
}

.search-name {
  width: 300px;
  margin-top: 10px;
}

.tree-organization {
  width: 300px;
  margin-left: 20px;
  margin-top: 20px;
  overflow-y: auto;
  max-height: 700px; /* 设置最大高度，超出时滚动显示 */
}
</style>
