<template>
  <div class="organization-list">
    <!-- 搜索组件和新增账号按钮的容器 -->
    <div class="search-add-container">
      <!-- 搜索组件 -->
      <search-bar @search="handleSearch" />

      <!-- 新增账号按钮组件 -->
      <add-account-button @added-successfully="loadData" />
    </div>
    <!-- 表格组件 -->
    <organization-table :organization-data="filteredOrganizationData"
      @data-changed="loadData" :loadData="loadData"  :loading="loading"/>

    <!-- 分页组件 -->
    <div class="pagination-container">
      <pagination-bar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
        @page-change="handlePageChange" @page-size-change="handlePageSizeChange" />
    </div>
  </div>
</template>

<script>
import SearchBar from "./OrganizationListComponents/SearchBar.vue";
import OrganizationTable from "./OrganizationListComponents/OrganizationTable.vue";
import PaginationBar from "@/components/PaginationBar.vue";
import AddAccountButton from "./OrganizationListComponents/AddAccountButton.vue";
import { getRoleUsersData, getUsersData } from "@/api/api";

export default {
  components: {
    SearchBar,
    OrganizationTable,
    PaginationBar,
    AddAccountButton, // 在这里注册新增账号按钮组件
  },
  props: {
    selectedOrganization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchType: "account",
      searchKeyword: "",
      organizationData: [], // Your organization data
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      searchParams: {},
      loading: false, // 加载状态
    };
  },
  created() {
    this.loadData(this.selectedOrganization);
  },
  watch: {
    selectedOrganization: {
      immediate: true,
      handler(newVal) {
        this.loadData(newVal);
      },
    },
  },
  methods: {
    async handleSearch(searchParams) {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.searchParams = searchParams;


      this.loading = true; // 开始加载
      try {
        let response;
        if (this.selectedOrganization) {
          this.searchParams['organization_id-eq'] = this.selectedOrganization.key;
        }

        if (this.searchParams['email-like'] !== undefined || this.searchParams["name-like"] !== undefined) {
          // 如果搜索参数中包含账号或邮箱，调用 getUsersData 接口
          response = await getUsersData(page, pageSize, this.searchParams);
        } else if (this.searchParams["role"]) {
          // 如果搜索参数中包含角色ID，调用另一个接口
          response = await getRoleUsersData(page, pageSize, this.searchParams);
        } else {
          // 如果搜索参数为空，调用 getUsersData 接口获取全部数据
          response = await getUsersData(page, pageSize, this.searchParams);
        }

        this.organizationData = response.data.map(item => ({
          id: item.id,
          name: item.name,
          account: item.email, // 根据值映射中文名称
          parentOrganization: item.organization,
          role: item.role,
          createTime: item.created_at,
        }));
        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = parseInt(response.meta.per_page);

      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false; // 加载结束
      }
    },
    async handlePageChange(newPage) {
      try {
        this.currentPage = newPage;
        await this.handleSearch(this.searchParams);
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async handlePageSizeChange(newPageSize) {
      // 异步操作的处理逻辑
      try {
        this.pageSize = newPageSize;
        await this.handleSearch(this.searchParams);

      } catch (error) {
        console.error('Error:', error);
      }
    },

    async loadData(selectedOrganization) {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.loading = true; // 开始加载
      try {
        let data;
        if (selectedOrganization) {
          // 使用选定的组织 ID 进行数据加载
          data = await getUsersData(page, pageSize, { 'organization_id-eq': selectedOrganization.key });
        } else {
          // 加载所有数据
          data = await getUsersData(page, pageSize);
        }
        // 将从服务器获取的数据进行字段重命名
        this.organizationData = data.data.map(item => ({
          id: item.id,
          name: item.name,
          account: item.email,
          parentOrganization: item.organization,
          role: item.role,
          createTime: item.created_at,
        }));
        this.totalRecords = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.pageSize = typeof data.meta.per_page === 'string' ? parseInt(data.meta.per_page, 10) : data.meta.per_page;
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false; // 加载结束
      }
    },

  },
  computed: {
    filteredOrganizationData() {
      if (!this.selectedOrganization) {
        return this.organizationData;
      }
      return this.organizationData.filter(item => item.parentOrganization === this.selectedOrganization.label);
    },
  },
};
</script>
<style scoped>
.organization-list {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.search-add-container {
  display: flex;
  align-items: center;
  /* 对齐项目 */
  justify-content: space-between;
  /* 如果你想让按钮和搜索栏分开 */
}

.pagination-container {
  align-self: flex-end;
}
</style>