<template>
  <div class="add-account-button">
    <el-button class="button-background-color" @click="addAccount">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp;{{ $t('userManage.addAccount') }}
    </el-button>
  </div>

  <el-dialog v-model="addAccountDialogVisible" width="20%" style="text-align: left;" @open="disableScroll" @close="enableScroll">

    <template #header>
      {{ $t('userManage.addAccount') }}
    </template>

    <hr class="top-separator"/>

    <el-form :model="addAccountForm" :rules="addAccountFormRules" ref="addAccountForm" label-width="100px"
             label-position="top">

      <el-form-item :label="$t('userManage.name')" prop="accountName">
        <el-input v-model="addAccountForm.accountName" :placeholder="$t('userManage.enterName')" clearable></el-input>
      </el-form-item>

      <el-form-item prop="roleName">
        <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('userManage.role') }}</label>
        <el-select v-model="addAccountForm.roleName" :placeholder="$t('userManage.pleaseSelectRole')"
                   style="width: 100%;" multiple filterable append-to-body
                   clearable>
          <el-option v-for="item in roleNameOptions" :key="item.value" :value="item.value"
                     :label="item.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('userManage.organization')" prop="organizationName">
        <el-tree-select v-model="addAccountForm.organizationName" :data="organizationOptions"
                        :props="{ label: 'label', children: 'children' }" check-strictly  autocomplete="off"
                        :placeholder="$t('userManage.selectBelongOrganization')"
                        :render-after-expand="false" filterable clearable style="width: 100%;"/>
      </el-form-item>

      <el-form-item prop="selectedCountries">
        <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('userManage.country') }}</label>
        <el-select v-model="addAccountForm.selectedCountries" :placeholder="$t('userManage.selectCountry')"
                   prefix-icon="el-icon-location"  autocomplete="off"
                   clearable style="width: 100%;" label-position="top" multiple filterable
                   @change="handleCountryChange"  :reserve-keyword="false">
          <el-option key="all" :label="$t('userManage.selectAll')" value="all"/>
          <el-option v-for="item in countryOptions" :key="item.value" :label="item.label" :value="item.value"/>

        </el-select>
      </el-form-item>
      <el-form-item :label="$t('userManage.emailAccount')" prop="emailOrAccount">
        <el-input v-model="addAccountForm.emailOrAccount" :placeholder="$t('userManage.enterEmail')"
                  clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('userManage.password')" prop="accountPassword">
        <el-input v-model="addAccountForm.accountPassword" :placeholder="$t('userManage.enterPassword')"
                  :show-password="true"></el-input>
        <span class="warning-text">{{ $t('userManage.passwordRequirement') }}</span>
      </el-form-item>

    </el-form>

    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button @click="cancelAddAccount" class="custom-reset-button">{{ $t('userManage.cancel') }}</el-button>
      <el-button class="button-background-color" @click="confirmAddAccount" :loading="isButtonLoading">{{ $t('userManage.confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Plus} from "@element-plus/icons-vue";

import {addAccount, getOrganizationsData, getRoleData, listToTree} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";
import countryMixin from "@/mixins/countryMixin";

export default {
  emits: ['add', 'added-successfully'], // 在 emits 中声明可以触发的自定义事件名称
  components: {Plus},
  data() {
    return {
      addAccountDialogVisible: false,
      addAccountForm: {
        accountName: '',
        roleName: [],
        organizationName: '',
        selectedCountries: '',
        emailOrAccount: '',
        accountPassword: '',
      },
      addAccountFormRules: {
        accountName: [
          {
            required: true, message: this.$t('userManage.enterName'), trigger: 'blur'
          },
        ],
        roleName: [
          {
            required: true, message: this.$t('userManage.pleaseSelectRole'), trigger: 'blur'
          },
        ],
        organizationName: [
          {
            required: true, message: this.$t('userManage.pleaseSelectOrganization'), trigger: 'blur'
          },
        ],

        emailOrAccount: [
          {
            required: true, message: this.$t('ProductDiagnosis.enterEmail'), trigger: 'blur'
          }
        ],

        selectedCountries: [
          {
            required: true, message: this.$t('userManage.selectCountry'), trigger: 'blur'
          }
        ],
        accountPassword: [
          { required: true, message: this.$t('userManage.pleaseEnterPassword'), trigger: 'blur' },
          { min: 8, max: 20, message: this.$t('userManage.passwordLength'), trigger: 'blur' },
          { pattern: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,20}$/, message: this.$t('userManage.passwordPattern'), trigger: 'blur' }

        ],

      },
      roleNameOptions: [],

      organizationOptions: [],

      countryOptions: [],

      dataLoaded: false, // 新增的标志位
    }
  },
  mixins: [dialogMixin,countryMixin],
  methods: {
    async addAccount() {
      if (!this.dataLoaded) {
        await this.fetchOrganizationOptions();
        await this.fetchCountryOptions();
        await this.fetchRoleNameOptions();
        this.dataLoaded = true; // 设置标志位为true
      }
      // 触发新增账号操作
      this.addAccountDialogVisible = true;
      this.$emit("add");
    },
    cancelAddAccount() {
      this.resetAccountForm();
      this.addAccountDialogVisible = false;
    },
    resetAccountForm() {
      this.addAccountForm = {
        accountName: '',
        roleName: [],
        organizationName: '',
        selectedCountries: '',
        emailOrAccount: '',
        accountPassword: '',
      }
    },
    async confirmAddAccount() {
      await this.$refs.addAccountForm.validate(async (valid) => {
        if (valid) {

          await this.handleButtonAction(async () => {
            // 构建发送到后端的数据对象
            const accountData = {
              name: this.addAccountForm.accountName, // 映射email字段
              email: this.addAccountForm.emailOrAccount, // 映射email字段
              country_ids: this.addAccountForm.selectedCountries, // 映射password字段
              role_ids: this.addAccountForm.roleName, // 映射password字段
              password: this.addAccountForm.accountPassword, // 映射password字段
              organization_id: this.addAccountForm.organizationName, // 映射password字段
            };
            const response =await addAccount(accountData);
            // 检查后端返回的状态码
            if (response.code === -1) {
              // 如果状态码为-1，弹出提示信息
              this.$message.error(response.message);
            } else {
              // 如果状态码不是-1，表示添加成功
              // 重置form表单
              this.resetAccountForm();
              this.addAccountDialogVisible = false;
              // 这里可以添加一些成功的后续操作，如刷新列表等
              this.$emit('added-successfully'); // 触发事件
            }
          });
        }
      });
    },

    handleCountryChange(value) {
      if (value.includes('all')) {
        // 检查是否选择了“全选”
        if (this.addAccountForm.selectedCountries.length === this.countryOptions.length + 1) {
          // 如果已全选，点击全选将取消选择所有项
          this.addAccountForm.selectedCountries = [];
        } else {
          // 否则，选择所有国家
          this.addAccountForm.selectedCountries = this.countryOptions.map(item => item.value);
        }
      } else if (this.addAccountForm.selectedCountries.length === this.countryOptions.length) {
        // 如果所有国家都被选中，确保全选也被选中
      } else {
        // 如果不是所有国家被选中，确保全选不被选中
        this.addAccountForm.selectedCountries = this.addAccountForm.selectedCountries.filter(item => item !== 'all');
      }
    },

    async fetchOrganizationOptions() {
      try {
        const response = await getOrganizationsData();
        const treeData = listToTree(response.data);
        this.organizationOptions = this.transformOrganizationsData(treeData);
      } catch (error) {
        console.error('Error fetching organization options:', error);
      }
    },
    transformOrganizationsData(organizations) {
      return (organizations || []).map(org => ({
        value: org.id,
        label: org.name,
        children: this.transformOrganizationsData(org.children),
      }));
    },
    async fetchRoleNameOptions() {
      try {
        // 调用后端接口获取国家列表数据
        const response = await getRoleData();
        // 将后端返回的国家列表数据转换为前端需要的格式
        this.roleNameOptions = response.data.map(role => ({
          value: role.id,
          label: role.name
        }));
      } catch (error) {
        console.error('Error fetching role options:', error);
        // 处理错误
      }
    },
  },
};
</script>

<style scoped>
.warning-text {
  font-size: 14px;
  color: rgb(191, 191, 191);
}

:deep(.el-form-item__label) {
  margin-bottom: 0;
  /* 减少label下方的外边距 */
  padding-bottom: 0;
  /* 减少label的内边距 */
}

.add-account-button {
  margin-bottom: 20px;
  text-align: left;
}
</style>